import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Header from '../components/Header';
import { Grid, Paper } from '@material-ui/core';
import COLORS from '../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import { shape, func } from 'prop-types';
import MailingFrom from '../components/Forms/MailingFrom';
import PageContainer from '../components/Containers/PageContainer';
import ContactCard from '../components/Cards/ContactCard';

const useStyles = makeStyles(theme => ({
  pageTopSection: {
    color: COLORS.WHITE,
    '&>*': {
      marginBottom: 7,
      maxWidth: '60%',
      [theme.breakpoints.down('sm')]: { maxWidth: '100%' },
    },
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
    '&>*': {
      padding: theme.spacing(10),
      [theme.breakpoints.down(1400)]: {
        padding: theme.spacing(7),
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
    },
    '& .MuiPaper-root': {
      padding: 50,
      [theme.breakpoints.down('md')]: { padding: 30 },
    },
  },
  messageGridContainer: {
    '& h3,p': {
      color: COLORS.BLACK,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const Contact = ({ intl, data }) => {
  const classes = useStyles();
  const { formatMessage } = intl;
  /*TODO:Change data source graphql.*/
  const siteTitle = data.site.siteMetadata.title;
  // const handleSentMessage = ({
  //   email,
  //   phoneN,
  //   company,
  //   message,
  // }) => {
  //   /*TODO:Fix this function to be able to receive email from the customers */
  //   alert(
  //     `from: ${email}\nphone:${phoneN}\ncompnay:${company}\nmessage:${message}`
  //   );
  // };

  return (
    <Layout>
      {/*TODO:Improve SEO and Helmet props values */}
      <SEO
        title="HeadzUp Sport AB"
        description="Kontakta HeazUp sport AB, Pierré Johnsson"
        keywords={['Kontakta']}
      />
      <Helmet title={siteTitle} />
      <PageContainer>
        <div className={classes.pageTopSection}>
          <Header secondary>
            {formatMessage({ id: 'contact.header' })}
          </Header>
          <p>
            {/* {formatMessage({ id: 'contact.description' })} */}
          </p>
        </div>
        <Grid container className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.messageGridContainer}
          >
            <Paper square>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header sub upperCase={false}>
                    {formatMessage({
                      id: 'contact.messageForm.header',
                    })}
                  </Header>
                  <p>
                    {formatMessage({
                      id: 'contact.messageForm.description',
                    })}
                  </p>
                </Grid>
                <MailingFrom
                //TODO:Activate mailing form.
                />
              </Grid>
            </Paper>
          </Grid>
          <ContactCard xs={12} sm={6} />
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Contact.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
  data: shape().isRequired,
};

export default injectIntl(Contact);

/*TODO:Implement correct query */
export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
